import 'intl';
import 'intl/locale-data/jsonp/en-GB';
import 'intl/locale-data/jsonp/es-ES';
import 'intl/locale-data/jsonp/fr-CA';
import React, { useEffect } from 'react';

import { GlobalTheme } from '@carbon/react';
import '@formatjs/intl-getcanonicallocales/polyfill';
import '@formatjs/intl-locale/polyfill';
import '@formatjs/intl-pluralrules/locale-data/ar';
import '@formatjs/intl-pluralrules/locale-data/de';
import '@formatjs/intl-pluralrules/locale-data/en';
import '@formatjs/intl-pluralrules/locale-data/es';
import '@formatjs/intl-pluralrules/locale-data/fr';
import '@formatjs/intl-pluralrules/locale-data/it';
import '@formatjs/intl-pluralrules/polyfill';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faArchive,
  faBookReader,
  faChartArea,
  faCog,
  faPlayCircle,
  faQuestionCircle,
  faTachometerAlt,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import 'dayjs/locale/ar';
import 'dayjs/locale/de';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/es';
import 'dayjs/locale/fr';
import 'dayjs/locale/it';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router } from 'react-router-dom';

import CookieConsent from 'components/CookieConsent';
import PlatformFeedbackProvider from 'components/PlatformFeedback/PlatformFeedbackContext';
import Bootstrap from 'routes/components/Bootstrap';
import { useAppSelector } from 'store/createStore';
import { selectTheme, selectThemeState } from 'store/features/themeSlice';
import GlobalStyle from 'theme/globalStyles';
import ThemeProvider from 'ThemeProvider';

import { ApiDataProvider } from './api';
import BlockAdminProvider from './components/Content/BlockAdmin/BlockAdminProvider';
import Main from './Main';
import OriginRouteProvider from './routes/OriginRouteProvider';
import { updateCarbonThemeProperties } from './utils/carbonThemeProperties';
import './styles/App.css';

const App = () => {
  const userInfo = useAppSelector((state) => state.main.userInfo);
  const themeState = useAppSelector(selectThemeState);
  const theme = useAppSelector(selectTheme);
  const carbonTheme = theme.name === 'light' ? 'white' : 'g100';

  useEffect(() => {
    /* Update carbon theme based on the branding theme */
    document.documentElement.dataset.carbonTheme = carbonTheme;

    if (userInfo?.id) {
      updateCarbonThemeProperties(theme, themeState.primaryColor);
    }
  }, [theme, carbonTheme, themeState, userInfo]);

  return (
    <React.StrictMode>
      <HelmetProvider>
        <GlobalTheme theme={carbonTheme}>
          <ThemeProvider theme={theme}>
            <GlobalStyle />
            <CookieConsent />
            <ApiDataProvider>
              <BlockAdminProvider>
                <PlatformFeedbackProvider>
                  <OriginRouteProvider>
                    <Router>
                      <Bootstrap>
                        <Main />
                      </Bootstrap>
                    </Router>
                  </OriginRouteProvider>
                </PlatformFeedbackProvider>
              </BlockAdminProvider>
            </ApiDataProvider>
          </ThemeProvider>
        </GlobalTheme>
      </HelmetProvider>
    </React.StrictMode>
  );
};

library.add(
  faTachometerAlt,
  faChartArea,
  faPlayCircle,
  faBookReader,
  faUsers,
  faArchive,
  faCog,
  faQuestionCircle,
);

export default App;
