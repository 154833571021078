import React, { useEffect, useState } from 'react';

import {
  HeaderMenuItem,
  HeaderSideNavItems,
  SideNavItems,
  SideNavLink,
  SideNavMenu,
  SwitcherDivider,
} from '@carbon/react';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { Link, Location, useLocation } from 'react-router-dom';

import Translation from 'components/Content/Translation/Translation';
import ThemeToggle from 'components/ThemeToggle/ThemeToggle';

import { useFeatureFlag } from '../../../feature-flags';
import { MenuItemType } from '../MenuData/MenuItems.types';

import MenuItemIcon from './components/MenuItemIcon';
import MenuTextWithTags from './components/MenuTextWithTags';
import PoweredByCybSafe from './components/PoweredByCybSafe';
import { BottomSection, StyledSideNav, TopMenu } from './SideNav.styles';

function formatItems(menuItemsCopy, pathname) {
  let active = false;
  const formattedItems = menuItemsCopy.map((items) => {
    if (items.sub && items.sub.length > 0) {
      const { formattedItems: subFormatted, active: subActive } = formatItems(
        items.sub,
        pathname,
      );
      items.sub = subFormatted;
      items.expanded = subActive;
      if (subActive) active = true;
      return items;
    } else {
      items.active = pathname === items?.url;
      if (pathname === items?.url) active = true;
      return items;
    }
  });
  return { formattedItems, active };
}

export interface CybSafeSideNavProps {
  isSideNavExpanded: boolean;
  menuItems: MenuItemType[];
  headerItems: MenuItemType[];
  hasCustomLogo: boolean;
  withSideMenu: boolean;
  withTopMenu: boolean;
  direction: DirectionType;
  isAdmin: boolean;
  userGroup?: number;
  updateFeatureMap?: (featureMap: Record<string, Array<string>>) => void;
  hasDelegatedAccess: boolean;
  setIconMenuExpanded: (iconMenuExpanded: boolean) => void;
  iconMenuExpanded: boolean;
}

const getUrlFeatures = (manuItem: MenuItemType[]) => {
  let urlFeaturesRecord: Record<string, Array<string>> = {};
  manuItem.forEach((item) => {
    if (item.sub && item.sub.length > 0) {
      const childrenUrlFeatures = getUrlFeatures(item.sub);
      urlFeaturesRecord = { ...urlFeaturesRecord, ...childrenUrlFeatures };
    } else {
      if (item.url) {
        urlFeaturesRecord = {
          ...urlFeaturesRecord,
          [item.url]: item.features ?? [],
        };
      }
    }
  });
  return urlFeaturesRecord;
};

const recursivelyCheckIfActive = (
  items: MenuItemType | MenuItemType[],
  location: Location<unknown>,
) => {
  if (!Array.isArray(items)) {
    items = [items];
  }

  return items.some((item) => {
    if (item.active) {
      return true;
    }
    if (item.url && location.pathname.startsWith(item.url)) {
      return true;
    }
    if (item.sub) {
      return recursivelyCheckIfActive(item.sub, location);
    }
    return false;
  });
};

export default function CybSafeSideNav({
  isSideNavExpanded,
  menuItems,
  headerItems,
  withSideMenu,
  withTopMenu,
  direction,
  hasCustomLogo,
  isAdmin,
  userGroup,
  updateFeatureMap,
  hasDelegatedAccess,
  setIconMenuExpanded,
  iconMenuExpanded,
}: CybSafeSideNavProps) {
  const useIconSideNav = useFeatureFlag('use-icon-side-nav');
  const intl = useIntl();
  const location = useLocation();
  const isConnectApp = localStorage.getItem('isConnectApp');

  const [sidebarTopItems, setSidebarTopItems] = useState<MenuItemType[]>([]);
  const [topItems, setTopItems] = useState<MenuItemType[]>([]);

  useEffect(() => {
    const menuItemsCopy = menuItems.slice();
    const headerItemsCopy = headerItems.slice();
    const { formattedItems: formattedSideTopItems } = formatItems(
      menuItemsCopy,
      location.pathname,
    );

    const { formattedItems: formattedHeaderItems } = formatItems(
      headerItemsCopy,
      location.pathname,
    );
    setSidebarTopItems(formattedSideTopItems);
    setTopItems(formattedHeaderItems);

    const sideTopUrlFeatureMap = getUrlFeatures(formattedSideTopItems);

    const headerUrlFeatureMap = getUrlFeatures(formattedHeaderItems);
    const urlFeaturesMap = { ...sideTopUrlFeatureMap, ...headerUrlFeatureMap };

    Object.values(urlFeaturesMap).length > 0 &&
      updateFeatureMap &&
      updateFeatureMap(urlFeaturesMap);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuItems, headerItems, location.pathname]);

  const submenuItems = (item: MenuItemType) =>
    item.sub &&
    item.sub
      .filter(
        ({ displayAsTab = false, sideNavEntryPoint = false }) =>
          !displayAsTab || (displayAsTab && sideNavEntryPoint),
      )
      .map((subItem, index) => {
        if (
          subItem.sub &&
          subItem.sub.length > 0 &&
          !(subItem.sideNavEntryPoint ?? false)
        ) {
          return (
            <SideNavMenu
              title={intl.formatMessage({ id: subItem.label })}
              key={`${subItem.label}_${index}`}
              isActive={
                item.active || recursivelyCheckIfActive(subItem.sub, location)
              }
              defaultExpanded={subItem.expanded}
            >
              {submenuItems(subItem)}
            </SideNavMenu>
          );
        } else {
          let isActive;
          if (subItem.active) {
            isActive = true;
          } else {
            isActive =
              subItem.sub && subItem.sub.length > 0
                ? recursivelyCheckIfActive(subItem.sub, location)
                : // NOTE: This is a workaround for the page genarator tabs which are not part of menu items but need to show an active side bar item
                  subItem.url && subItem.url.startsWith('/admin/page')
                  ? location.pathname.includes(subItem.url)
                  : false;
          }

          return (
            <SideNavLink
              key={`${subItem.label}_${index}`}
              id={subItem.label}
              as={subItem.isExternalLink ? 'a' : Link}
              {...(!subItem.isExternalLink && {
                to: subItem.url,
              })}
              {...(subItem.isExternalLink && {
                href: subItem.url,
                target: '_blank',
              })}
              isActive={isActive}
            >
              <>
                <Translation id={subItem.label}>
                  {(txt) => (
                    <MenuTextWithTags
                      title={txt}
                      className="cds--side-nav__link-text"
                      tags={subItem.tags}
                    />
                  )}
                </Translation>
                {subItem.isExternalLink && (
                  <FontAwesomeIcon icon={faExternalLinkAlt} />
                )}
              </>
            </SideNavLink>
          );
        }
      });

  return (
    <StyledSideNav
      data-testid="side-menu"
      aria-label="Side navigation"
      expanded={isSideNavExpanded}
      $isAdmin={isAdmin || userGroup === 7}
      $withSideMenu={withSideMenu}
      $hasDelegatedAccess={hasDelegatedAccess}
      isRail={useIconSideNav}
      enterDelayMs={0}
      onToggle={(_event, value) => {
        /* The below works with the isRail prop. When the icon menu expands,
        this sets state in the Layout component to push the content on the page,
        so that the menu isn't overlapping the content */
        setIconMenuExpanded(value);
      }}
    >
      <SideNavItems>
        {withTopMenu && topItems.length > 0 && (
          <HeaderSideNavItems hasDivider={true}>
            {topItems.map((item, index) => (
              <HeaderMenuItem
                key={index}
                id={`${item.label}-top`}
                target={item.isExternalLink ? '_blank' : undefined}
                as={item.isExternalLink ? 'a' : Link}
                {...(item.isExternalLink && { href: item.url })}
                {...(!item.isExternalLink && { to: item.url })}
              >
                <Translation id={item.label} />
              </HeaderMenuItem>
            ))}
          </HeaderSideNavItems>
        )}
        {!isConnectApp &&
          sidebarTopItems.map((item, index) => {
            if (
              item.sub &&
              item.sub.length > 0 &&
              !item.sideNavEntryPoint &&
              !(item.displayAsTab ?? false)
            ) {
              return (
                <TopMenu
                  dir={direction}
                  key={index}
                  isSideNavExpanded={iconMenuExpanded}
                  defaultExpanded={item.expanded}
                  isActive={item.sub.filter((i) => i.active).length > 0}
                  {...(item.icon && {
                    renderIcon: () => <MenuItemIcon icon={item.icon!} />,
                  })}
                  title={intl.formatMessage({ id: item.label })}
                >
                  {submenuItems(item)}
                </TopMenu>
              );
            } else if (item.divider) {
              return (
                <li>
                  <SwitcherDivider key={index} />
                </li>
              );
            } else {
              return (
                <SideNavLink
                  key={index}
                  id={item.label}
                  {...(item.icon && {
                    renderIcon: () => <MenuItemIcon icon={item.icon!} />,
                  })}
                  as={item.isExternalLink ? 'a' : Link}
                  {...(item.isExternalLink && { href: item.url })}
                  {...(!item.isExternalLink && { to: item.url })}
                  {...(item.isExternalLink && { target: '_blank' })}
                  className={classNames('cds--side-nav__link', {
                    'cds--side-nav__link--current': recursivelyCheckIfActive(
                      item,
                      location,
                    ),
                  })}
                >
                  <MenuTextWithTags
                    title={intl.formatMessage({ id: item.label })}
                    className="cds--side-nav__link-text"
                    tags={item.tags}
                  />
                  {item.isExternalLink && (
                    <FontAwesomeIcon
                      icon={faExternalLinkAlt}
                      color="currentColor"
                    />
                  )}
                </SideNavLink>
              );
            }
          })}
      </SideNavItems>
      <BottomSection>
        <ThemeToggle />
        {hasCustomLogo && <PoweredByCybSafe />}
      </BottomSection>
    </StyledSideNav>
  );
}
