import { createSelector, createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';

import { RootState } from '../createStore';

import {
  FeatureFlag,
  FeatureFlagsInterface,
  MainStateInterface,
  PackageRecord,
  Packages,
} from './mainSliceTypes';

export const initialState: MainStateInterface = {
  menu: [],
  countries: [],
  featureFlags: {},
  showFullPageLoadingScreen: false,
  showFullPageErrorScreen: false,
  canViewOtherOrganisations: false,
  userInfo: {
    profilePictureUrl: null,
  },
  clientInfo: {},
  config: {
    INTERCOM_APP_ID: '',
    cubeJwt: '',
  },
  skipIaAndCulture: false,
  cubeGroupId: 0,
  delegatedAccessClientId: null,
  eulaRequired: false,
  eulaPage: {
    defaultEula: undefined,
    partnerEula: undefined,
  },
  eulaPath: [],
  productPackages: [],
  urlFeatureMap: undefined,
  cultureRequired: false,
  cultureMandatory: false,
  initialAssessmentRequired: false,
};

export const DEFAULT_INTERCOM_APP_ID =
  process.env.NODE_ENV === 'production' ? 'ya7brvp1' : 'r0ixba26';

export const mainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    setFullPageLoadingScreen: (state, action) => {
      state.showFullPageLoadingScreen = action.payload;
    },
    setFullPageErrorScreen: (state, action) => {
      state.showFullPageErrorScreen = action.payload;
    },
    updateUserInfo: (state, action) => {
      state.userInfo = {
        ...state.userInfo,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        email: action.payload.email,
        defaultLanguage: action.payload.defaultLanguage,
      };
    },
    updateEULAInfo: (state, action) => {
      const clientInfo = state.clientInfo;
      const userInfo = state.userInfo;
      if (action.payload.clientEULA !== undefined)
        clientInfo.eula = action.payload.clientEULA;
      if (action.payload.userEULA !== undefined)
        userInfo.eula = action.payload.userEULA;
      state.clientInfo = clientInfo;
      state.userInfo = userInfo;
      state.eulaRequired = action.payload.eulaRequired;
    },
    setDelegateAccessClientId: (state, action) => {
      state.delegatedAccessClientId = action.payload;
    },
    clearDelegateAccessClientId: (state) => {
      state.delegatedAccessClientId = null;
    },
    setBootstrapData: (state, action) => {
      const payload = action.payload;
      state.canViewOtherOrganisations = payload.canViewOtherOrganisations;
      state.clientInfo = payload.clientInfo;
      state.userInfo = payload.userInfo;
      state.config = payload.config;
      state.eulaRequired = payload.eulaRequired;
      state.eulaPage = payload.eulaPage;
      state.eulaPath = payload.eulaPath;
      state.featureFlags = payload.featureFlags.reduce(
        (memo: FeatureFlagsInterface, flag: FeatureFlag) => {
          memo[flag.key] = flag;
          return memo;
        },
        {},
      );
      state.cultureRequired = payload.cultureRequired;
      state.cultureMandatory = payload.cultureMandatory;
      state.initialAssessmentRequired = payload.initialAssessmentRequired;
    },
    setInitialAssessmentRequired: (state, action) => {
      state.initialAssessmentRequired = action.payload;
    },
    setCultureRequired: (state, action) => {
      state.cultureRequired = action.payload;
    },
    setSkipIaAndCulture: (state, action) => {
      state.skipIaAndCulture = action.payload.skipIaAndCulture;
    },
    setPackagesData: (state, action) => {
      state.productPackages = action.payload.map(
        (productRecord: PackageRecord) => ({
          ...productRecord,
          package: productRecord.package.toUpperCase(),
        }),
      );
    },
    setCubeGroupId: (state, action) => {
      state.cubeGroupId = action.payload.cubeGroupId;
    },
    setUrlFeaturesMap: (state, action) => {
      state.urlFeatureMap = action.payload;
    },
  },
});

/* Actions */
export const {
  setFullPageLoadingScreen,
  setFullPageErrorScreen,
  updateUserInfo,
  updateEULAInfo,
  setDelegateAccessClientId,
  clearDelegateAccessClientId,
  setBootstrapData,
  setInitialAssessmentRequired,
  setCultureRequired,
  setSkipIaAndCulture,
  setPackagesData,
  setCubeGroupId,
  setUrlFeaturesMap,
} = mainSlice.actions;

/* Selectors */
export const selectUserInfo = (state: RootState) => state.main.userInfo;
export const selectClientInfo = (state: RootState) => state.main.clientInfo;
export const selectDelegatedAccessClientId = (state: RootState) =>
  state.main.delegatedAccessClientId;
export const selectClientName = (state: RootState) =>
  state.main.clientInfo.name;
export const selectClientId = (state: RootState) => {
  const clientId = state.main.userInfo.client;
  const delegatedAccessClientId = state.main.delegatedAccessClientId;
  return delegatedAccessClientId || clientId;
};
export const selectCubeGroupId = (state: RootState) => state.main.cubeGroupId;
export const selectIsCybSafeAdmin = (state: RootState) => {
  const userInfo = selectUserInfo(state);
  return userInfo?.userGroup === 1 || userInfo?.userGroup === 2;
};
export const selectIntercomAppId = (state: RootState) => {
  const config = state.main.config;
  return config.INTERCOM_APP_ID && config.INTERCOM_APP_ID !== ''
    ? config.INTERCOM_APP_ID
    : DEFAULT_INTERCOM_APP_ID;
};
export const selectIntercomInfo = createSelector(
  [selectClientInfo, selectUserInfo],
  (clientInfo, userInfo) => {
    return {
      userId: `${userInfo.id}`,
      name: `${userInfo.firstName} ${userInfo.lastName}`,
      userHash: userInfo.userHash,
      email: userInfo.email,
      customAttributes: {
        signed_up_at: dayjs(userInfo.created).unix(),
        is_admin: userInfo.isAdmin,
        client: userInfo.clientName,
        ia_completed:
          userInfo.iaRequired !== undefined
            ? !userInfo.iaRequired
            : userInfo.iaRequired,
        refresher_required: userInfo.refresherRequired,
      },
      company: {
        companyId: `${userInfo.client}`,
        name: userInfo.clientName,
        customAttributes: {
          is_demo: clientInfo.isDummy,
          plan: clientInfo.acctType,
          licence_count: clientInfo.userLimit,
          expiry_date: dayjs(clientInfo.expiryDate).format('DD/MM/YYYY'),
          website: clientInfo.clientWebsite,
          customer_success_segmentation: clientInfo.csSegmentationField,
          sic_code: clientInfo.sicCode,
        },
      },
    };
  },
);
export const selectFeatureFlags = (state: RootState): FeatureFlagsInterface => {
  return state.main.featureFlags;
};
export const selectPackages = (state: RootState): Packages => {
  return state.main.productPackages;
};
export const selectClientPackages = (
  state: RootState,
): Packages | undefined => {
  const clientInfo = selectClientInfo(state);
  return clientInfo.packages;
};
export const selectClientFeatures = createSelector(
  [selectClientPackages],
  (clientPackages) => {
    if (!clientPackages) return [];
    const features = clientPackages.map(
      (packageRecord: PackageRecord) => packageRecord.features,
    );
    return ([] as string[]).concat(...features);
  },
);
export const hasGuideLiteOnlyAccess = (state: RootState): boolean => {
  const clientInfo = selectClientInfo(state);
  const packages = clientInfo.packages;
  if (!packages) return false;
  const levels = packages.map(
    (packageRecord: PackageRecord) => packageRecord.package,
  );
  return levels.length === 1 && levels[0].toUpperCase() === 'GUIDE LITE';
};
export const selectUrlFeatureMap = (
  state: RootState,
): Record<string, Array<string>> | undefined => {
  return state.main.urlFeatureMap;
};

/* Persist */
const mainConfig = {
  key: 'main',
  storage,
  whitelist: ['delegatedAccessClientId'],
};

export default persistReducer(mainConfig, mainSlice.reducer);
